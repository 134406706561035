import { DavidRothschild, EladYomTov } from './authors';

export const BadMedicine = {
  title:
    'Bad Medicine: Understanding the Movement and Proliferation of Medical Hoaxes During the COVID-19 Pandemic',
  description:
    'We leverage large-scale news and query data to quantify how medical hoaxes moved throughout the infosphere during the COVID-19 pandemic.',
  authors: [DavidRothschild, EladYomTov],
};

export const RetirementElasticity = {
  title: 'Elasticity of Retirement Investing',
  description:
    'I use IRS Form 5500 data to estimate the elasticity of retirement investing with respect to marginal income tax rates.',
  authors: [],
};

export default [BadMedicine, RetirementElasticity];
