import { HuntAllcott, DmitryTaubinsky, WilliamMorrison } from './authors';

export const OptimalNPI = {
  title: 'When Do "Nudges" Increase Welfare?',
  paperUrl:
    'https://www.dropbox.com/scl/fi/r7esbzpyeu5pwojxi83tc/NudgeWelfare.pdf?rlkey=d7glp5wklv2uu1nudtjr5eqn3&dl=0',
  repoUrl:
    'https://www.dropbox.com/scl/fi/t6flwxjy57ek74dxtvom7/OptimalNPI_replication.zip?rlkey=j564xs9k6ftwi64fll417aroh&dl=0',
  authors: [HuntAllcott, WilliamMorrison, DmitryTaubinsky],
  status: 'Conditionally accepted at the ',
  venue: 'American Economic Review.',
};

export default [OptimalNPI];
