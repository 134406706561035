export const DanielCohen = {
  name: 'Daniel Cohen',
  url: 'https://danielmorsecohen.com/#/',
};

export const HuntAllcott = {
  name: 'Hunt Allcott',
  url: 'https://allcott.stanford.edu/',
};

export const DmitryTaubinsky = {
  name: 'Dmitry Taubinsky',
  url: 'https://www.dmitrytaubinsky.com/',
};

export const WilliamMorrison = {
  name: 'William Morrison',
  url: 'https://www.cornerstone.com/professionals/william-morrison/',
};

export const DavidRothschild = {
  name: 'David Rothschild',
  url: 'https://researchdmr.com/',
};

export const EladYomTov = {
  name: 'Elad Yom-Tov',
  url: 'http://www.yom-tov.info/',
};
